<template>
    <section id="hero">
        <base-jumbotron
                :src="src"
        >
            <v-container
                    fill-height
            >
                <v-layout
                        align-center
                        justify-center
                >
                        <base-title>
                            {{$t('agenda.agenda')}}
                        </base-title>
                </v-layout>
            </v-container>
        </base-jumbotron>
    </section>
</template>

<script>
    export default {
        name:'Hero',
        data: () => ({
            src: require('@/assets/img/jan_berlin.jpg')
        })
    }
</script>
